<template> <!-- 구매대행 -->
<span/>
<div>
  <div v-if="idx === '0'"> <!-- 구매대행 -->
    <p>대행을 원하시면 가장 먼저 geek9.kr에 대행 신청을 해주시면 되고, 요청사항은 geek9.kr에 대행 신청을 하실 때 요청사항에 써주시면 됩니다.</p>
    <p>구매대행은 구매를 원하시는 해외의 상품을 GEEK9에서 직접 구매를 해서 한국으로 보내는 드리는 것을 말합니다. 판매가의 10%가 수수료로 발생하고, 한국으로 보내는 배송비는 별도입니다. 추가로 해당 상품의 판매가가 미국 달러 기준으로 150달러(미국에서 해외직구하는 경우 200달러)가 넘으면 한국의 세관에서 관세와 부가세가 발생합니다. 관부가세는 아래 링크로 가시면 대략적인 비용 조회 가능하세요. 그 외 추가적으로 발생하는 비용은 없습니다.</p>
    <p><a href="https://search.naver.com/search.naver?query=%EA%B4%80%EB%B6%80%EA%B0%80%EC%84%B8+%EA%B3%84%EC%82%B0%EA%B8%B0" target="_blank"> 관부가세 계산기</a></p>
    <p>한국으로 보내는 배송비는 최종적으로 포장이 완료된 택배의 무게와 부피에 따라 결정되기 때문에 물건을 구매해서 GEEK9에서 해당 물건을 받은 후에 알려드릴 수 있습니다. 당연히 배송비도 그때 따로 결제를 해주시면 되고, 결제가 확인되면 한국으로 배송을 보내드립니다.</p>
    <p>구매대행을 요청해 주실 때는 <span>구매를 원하시는 상품의 정확한 링크</span>와 <span>구매 수량</span>을 알려주셔야 됩니다. 이때 주의하실 점은 알려주신 상품 링크로 접속했을 때 나오는 페이지에 구매를 원하시는 상품의 정보(가격/옵션/주문버튼)가 나와야 한다는 점입니다.</p>
    <p>잘못된 상품 링크 <br>vinted.fr</p>
    <p>올바른 상품 링크 <br>Ex) https://www.vinted.fr/hommes/vetements-dexterieur/1008975794-veste-nike</p>
    <p>올바른 링크를 주셔야 바로 구매대행 가능 여부와 정확한 견적을 드릴 수 있습니다. 링크와 함께 받는 분의 성함, 주소, 폰 번호, 통관부호, 이메일 주소를 알려주시면 좀 더 빠른 진행이 가능합니다. 받는 분의 정보는 한글로 주시면 되고, 링크와 받는 분의 정보는 아래의 GEEK9 채널로 접속하셔서 카톡으로 주시면 됩니다! 구매대행이 가능한 국가는 <span>홍콩, 프랑스, 영국, 독일, 스페인, 이탈리아, 네덜란드, 일본, 중국, 미국</span>이지만 판매 사이트마다 제한이 있을 수 있기 때문에 구매를 원하시는 상품의 링크를 주셔야 구매대행 가능 여부와 정확한 견적을 바로 받으실 수 있습니다.</p>
    <p>개인 해외직구에 경우 가로/세로/높이 중 하나라도 150cm가 넘으면 배송이 불가할 수 있으므로 미리 대행 신청하시기 전에 반드시 문의해주세요</p>
    <p>구매까지 걸리는 시간은 해외 판매자가 답변을 얼마나 빨리 해주고, 얼마나 빨리 결제 정보를 알려주느냐에 따라 결정되기 때문에 GEEK9에서는 알 수 없습니다.</p>
  </div>
  <div v-if="idx === '1'"> <!-- 배송대행 -->
    <p>대행을 원하시면 가장 먼저 geek9.kr에 대행 신청을 해주시면 되고, 요청사항은 geek9.kr에 대행 신청을 하실 때 요청사항에 써주시면 됩니다.</p>
    <p>GEEK9가 해외에서 직접 구매까지 해서 한국으로 상품을 보내드리는 구매대행과는 달리 배송대행은 구매자 님께서 직접 구매를 하셔서 해당 국가의 GEEK9 해외 주소로 물건을 보내주셔야 됩니다. <strong>당연히 따로 수수료는 발생하지 않고, 한국으로 보내는 배송비만 발생합니다.</strong> 배송대행이 가능한 국가는 <span>홍콩,프랑스,영국,독일,스페인,이탈리아,일본,중국,미국,싱가포르,호주,네덜란드,오스트리아,뉴질랜드,스웨덴,베트남</span>입니다. 주소가 변동되거나 일시적으로 배송대행 업무를 하지 않는 경우가 있으니 꼭 문의하신 후에 배송 대행지 주소로 물건을 보내주시기 바랍니다.</p>
      <p>배송대행 신청 시 반드시 필요한 정보는 다음과 같습니다.</p>
    <p>
      <span>
      - 배송대행을 원하시는 상품의 링크<br>
      - 구매 수량<br>
      - 주문번호(Order Number) / 트래킹 넘버(Tracking Number)<br>
      - (한글) 받는 분의 성함 / 주소 / 폰 번호 / 통관부호 / 이메일
      </span>
    </p>
    <p>한국으로 보내드릴 때 합 배송 / 분리 배송 가능하고, 배송 한 건에 포장된 모든 상품의 가격이 미국 달러 기준으로 150달러(배송 대행지가 미국인 경우에는 200달러) 이상인 경우에는 한국 세관에서 관세와 부가세가 발생하기 때문에 이런 경우에는 세금이 나오지 않도록 분리 배송도 가능합니다. 그리고 미리 말씀해 주시면 <span>원산지 증명(FTA 통관)</span> 처리도 가능하니 배송대행 신청 시에 미리 꼭 말씀해 주세요!</p>
    <p>배송비는 한국으로 보내기 직전의 최종 포장된 박스의 무게와 부피에 따라 결정되기 때문에 정확한 배송비는 GEEK9에서 물건을 받은 후에 알려드릴 수 있습니다. 해당 배송비는 해외에서 한국으로 보내고, 한국에서 구매자 님까지 배송되는 비용입니다. 항공이나 선박(나라에 따라 다름)으로 한국으로 넘어간 후 한국에서는 우체국 택배나 CJ대한통운으로 구매자 님께 배송을 해드리고 있습니다. 가끔 DHL이나 Fedex, 우체국 EMS로 배송을 요청하시거나 한국이 아닌 다른 나라로 배송이 가능한지 문의하시는 경우가 있는데 GEEK9 홍콩 배송 대행지 주소로 보내주신 건에 대해서만 해당 작업들이 가능하고 그 외 국가는 불가합니다.</p>  
    <p>추가 포장이나 내용물의 사진을 요청하시는 경우에는 추가 비용이 발생하고, 상품의 하자나 잘못된 상품의 배송, 배송 중의 발생하는 파손이나 분실 등에 대해서는 GEEK9가 책임지지 않습니다.</p>
    <p>개인 해외직구에 경우 가로/세로/높이 중 하나라도 150cm가 넘으면 배송이 불가할 수 있으므로 미리 대행 신청하시기 전에 반드시 문의해주세요</p>
  </div>  
  <div v-if="idx === '2'"> <!-- 결제대행 -->
    <p>이체를 원하시는 해외 결제정보나 계좌정보를 주셔야 되고, 기본 결제대행 수수료는 8,000원이고, 이체 금액이 원화로 15만 원을 이상인 경우에는 기본 수수료 8,000원에 15만 원 당 3,000 원씩 추가됩니다.</p>
    <p><span>결제대행 신청을 해주시면 신청한 시점에 바로는 되지 않고, 빠르면 몇 시간, 늦으면 2일 이내로 이체가 가능합니다.</span></p>
    <p><span>이체 완료 이후에 결제대행 취소를 하시는 경우에는 판매자가 환불에 동의해주어야 취소가 가능하고, 판매자가 환불을 해주면 수수료를 차감한 액수만 환불해 드리고, 환율에 따라 금액에 변동이 있을 수 있습니다.</span></p>
    <p>이체가 완료되면 이체 내역서를 캡처해서 카톡으로 보내드립니다.</p>
  </div>
  <div v-if="idx === '3'"> <!-- 필수정보 -->
    <p>성함과 주소는 한글로 작성해주시면 됩니다.</p>
    <p><strong>대행국가</strong> : 대행을 맡기실 상품을 팔고 있는 국가</p>
    <p><strong>이름/통관부호/폰번호</strong> : 관세청에서 통관부호를 발급받기 위해 사용하신 성함과 폰번호를 알려주셔야 됩니다. 다른 정보로 알려주시면 한국 세관에서 문제가 될 수 있습니다.</p>
    <p><strong>이메일</strong> : 해당 이메일로 비용/진행상황 안내해드립니다.</p>
  </div> 
  <div v-if="idx === '4'"> <!-- 원산지증명(FTA 통관) -->
    <p><strong>[가능 국가]</strong><br>프랑스, 영국, 독일, 스페인, 미국, 중국, 일본, 호주(그 외 국가는 불가)</p>
    <p><strong>[절차]</strong><br>GEEK9에서 이미 구매한 후에 말씀해 주시면 원산지 증명(FTA 통관) 처리가 안될 수도 있으므로 원산지 증명을 원하시는 경우에는 반드시 대행신청하실 때 원산지증명을 선택해주셔야 됩니다. 그리고 GEEK9의 해외 주소지로 보내주신 물건에 대해서만 해당 작업 가능합니다. GEEK9에서 물건을 받은 후에 해당 국가에서 생산된 제품인 게 확인이 되면 그 이후 작업은 GEEK9에서 처리해 드리며, 같은 EU 국가라고 해서 서로 다른 유럽 국가에서 원산지 증명 처리가 되지는 않고, 배송을 보내는 국가에서 생산된 제품이어야 합니다)</p>
    <p><strong>[비용]</strong><br>제품이 생산된 국가와 배송을 보내는 국가가 일치하면 배송 한 건 당 원산지 처리 비용으로 만 원의 수수료가 추가됩니다. 한국으로 보내는 배송비 요청드릴 때 함께 청구하고, 원산지 증명 처리가 불가한 경우에는 배송비만 청구합니다.</p>
    <p><span>* 미국 달러 기준으로 150달러 이상의 상품을 구매하시게 되면 한국 세관에서 세금(관세와 부가세)이 발생합니다. 하지만 한국과 FTA 체결이 된 국가에서 개인이 해외 직구를 하는 경우에는 원산지 증명 처리를 하게 되면 관세가 면제되거나 덜 지불할 수 있습니다.(부가세는 동일하게 발생합니다)</span></p>
    <p>원산지증명처리를 신청하셔도 품목에 따라 처리가 되지 않을 수도 있습니다.</p>
  </div>   
  <div v-if="idx === '5'"> <!-- 예상견적 -->
    <p>1. 개인의 한국->홍콩 홍콩->한국 운임비용은 다음 링크에 설명되어 있습니다. <a href="https://blog.naver.com/since201109/222744520982" target="_blank">클릭</a></p>
    <p>2. 기업(업체) 배송비용은 물량에 따라 별도로 존재합니다(문의주시면 기업물류비용 안내드립니다) <a href="https://geek9.kr/qna" target="_blank">문의하기</a></p>
    <p>3. 해외 상품을 실제 구매하기 전에는 정확한 구매가를 알 수가 없습니다. 환율이 계속 변하고, 막상 결제를 하려고 하면 해외 현지 배송비나 구매 사이트 내 수수료와 같은 추가적인 비용이 추가될 수 있기 때문입니다. 그러므로 견적으로 나온 금액은 참고만 해주시기 바랍니다. 그리고 해당 견적 금액에는 GEEK9 수수료까지 포함된 금액이고, 해외에서 한국으로 보내는 배송비는 별도로 추가됩니다.</p>
    <p>4. Vinted와 Carousell 견적은 GEEK9 홈페이지에서 바로 조회 가능합니다. 다음의 링크로 가시면 Vinted와 Carousell 견적 조회하는 방법에 대해서 설명이 되어 있습니다. <a href="https://sosoceo.tistory.com/115" target="_blank">클릭</a></p>
  </div>
  <div v-if="idx === '6'"> <!-- 세금 -->
    <p>해외직구를 하기 위해서 구매대행이나 배송대행을 맡기시는 경우에는 대행 수수료, 배송비 외에도 한국 세관에서 발생하는 세금이 발생할 수도 있습니다. 해당 비용은 한국 세관에서 발생하는 것이기 때문에 대행 비용에는 추가되지 않는 별도 비용입니다. 하지만 한국 세관에서 세금이 무조건 발생하는 건 아니고, 상황에 따라 다른데 그중 가장 많은 경우는 다음과 같습니다.</p>
    <p>
      1. 해외에서 구매한 상품에 가격이 미국 달러 기준으로 150달러(미국에서 구매한 상품은 200달러)가 넘는 경우<br>
      2. 주류 구매 시
    </p>
    <p>
      <strong>[관세/부가세]</strong><br>
      미국 : 200usd<br>
      홍콩 : 1,000 ~ 1,100hkd<br>
      유로 : 110 ~ 120euro<br>
      영국 : 100 ~ 110파운드<br>
      일본 : 16,000 ~ 17,000엔<br>
      중국 : 900 ~ 1,000cny<br>
    </p>
    <p>환율은 계속 바뀌기 때문에 정확하게 얼마부터 관세/부가세가 나온다고 말씀을 드릴 수는 없고, 해당 국가에서 구매해서 한국으로 보낸 가격이 위의 가격대를 넘으면 한국 세관에서 무조건 관세/부가세가 발생합니다. 원산지증명(FTA 통관)을 하는 경우 관세의 면제나 할인이 가능하지만 부가세는 동일하게 발생합니다. 정확한 관세/부가세는 한국 세관에서 결정되지만 네이버에서 '관부가세 계산기'로 검색하시면 대략적인 관세와 부가세를 조회할 수 있습니다. 해외에서 구매하신 금액이 클수록 관세/부가세는 늘어나고 품목에 따라서 세율도 다릅니다.</p>
    <p>대행을 맡기실 때 관세/부가세 대납 요청을 하시면 GEEK9에서 관세/부가세를 납부한 후에 나중에 따로 청구해드릴 수도 있습니다(대납 비용 3,000원 추가됩니다)</p>
    <p>
      <strong>[주류]</strong><br>
      주류에 경우 구매하신 상품의 금액대와 상관없이 한국 세관에서 세금이 발생하고, 면세 기준은 한국 세관에 문의하시는 게 정확합니다. 주류의 알코올 도수, 부피, 수량, 국가에 따라 다르기 때문에 대행을 맡기시기 전에 한국 세관에서 문제 될 여지가 없는지, 세금이 얼마나 나오는지 꼭 확인해 보시기 바랍니다.
    </p>
  </div>
  <div v-if="idx === '7'"> <!-- 수수료 -->
    <p><strong>[구매대행]</strong><br>해외상품의 구매를 대행해 드리는 경우 해당 상품 해외판매가의 10%가 구매대행 수수료입니다. 단, 최소 수수료는 8,000원 입니다. 해외판매가는 해외 사이트에서 최종으로 결제하는 금액이고, 이 금액에 대행 수수료가 추가된 금액이 최종 소비자가 입니다. 단, 캐로셀(Carousell) 상품은 해외편지 배송비와 착불비, 해외이체 수수료가 추가됩니다!</p>
    <p><strong>[배송대행]</strong><br>해외 상품 구매는 직접 하시고 한국으로의 배송만 대행으로 맡기시는 경우에는 배송비만 발생합니다</p>
    <p><strong>[결제대행]</strong><br>해외 판매자에게 계좌 이체를 하는 경우에는 요청하신 시점에서 이틀 내로 해드리고, 수수료는 이체 금액이 원화로 15만 원 미만이면 8,000원, 15만 원 이상인 경우에는 기본 수수료 8,000원에 15만 원 당 3,000 원씩 추가됩니다.</p>
  </div>  
  <div v-if="idx === '8'"> <!-- 추가비용 -->
    <p>대행비, 배송비 이외에 추가적으로 발생할 수 있는 비용은 다음과 같습니다.</p>
    <p>
      1. 추가 포장(작업 후에 비용 알 수 있음)<br>
      2. 내용물 확인 및 사진 촬영(5,000원)<br>
      3. 밧데리가 있는 제품의 경우 최초 한 번은 검수 비용 발생(품목마다 비용 다름, 동일 제품의 두 번째 배송 때부터는 발생하지 않는 비용)<br>
      4. 관세/부가세 대납 수수료(5,000원)<br>
      5. 원산지 증명(FTA 통관) 처리 시 수수료(10,000원)<br>
      6. 부피가 큰 택배에 경우
    </p>
  </div>  
  <div v-if="idx === '9'"> <!-- 배송비 -->
    <p>1. 해외직구의 배송 비용은 한국으로 보내기 전, 포장이 완료된 상태 기준의 무게와 부피에 따라 결정되기 때문에 GEEK9의 해외 현지 사무실에서 물건을 받기 전에는 정확한 배송 비용을 알려드릴 수 없습니다. 그렇기 때문에 배송 비용은 GEEK9에서 운영하는 해외 현지 사무실에 물건이 도착한 이후에 별도로 결제 요청을 드립니다. 상품 상세 사진이나 재포장, 분리배송, 합배송, 보완 포장, 원산지 증명 시에도 비용이 추가되고, 마찬가지로 추가되는 금액은 해당 작업이 완료된 이후에 알려드릴 수 있습니다. 정확한 무게와 부피를 모르는 경우에는 거래가 빈번한 품목에 기준으로 아래에 가격을 명시해 놓았으니 참고하시기 바랍니다. <span>이탈리아, 스웨덴, 베트남, 뉴질랜드, 오스트리아, 네덜란드, 미국, 일본, 중국, 독일, 영국, 스페인, 프랑스, 홍콩, 호주, 베트남, 캐나다 등 대행이 가능한 모든 국가에 해당하는 금액이며, 무게와 포장 상태/방법에 따라 비용이 차이가 날 수 있습니다.</span></p>
    <p>2. 대행비는 물건 구입 + 대행수수료 + (해외)현지배송비가 포함된 금액이고, 한국으로 보내는 배송비는 별도입니다.</p>
    <p>3. GEEK9 홈페이지에서 예상 배송비 조회하는 방법(업체는 아래 글 참고) : <a href="https://sosoceo.tistory.com/115" target="_blank">클릭</a></p>
    <p>원화로 결제 가능합니다</p>
    <br>
    <p><strong>[개인 - 모든 국가 배송비(배송대행비) 계산 방법]</strong></p>
    <p><a href="https://geek9.kr" target="_blank">geek9.kr</a> 홈페이지의 첫 페이지에서 무게와 부피로 예상 배송비 바로 조회 가능합니다.</p>
    <br>
    <p><strong>[개인 - 홍콩배송비 계산 방법]</strong></p>
    <p> - 홍콩배송비 = (기본 114hkd) + (0.5kg 당 25hkd)</p>
    <p> - 기준환율 : 홍콩달러(HKD)</p>
    <p> - 실제무게와 부피무게(가로(cm) x 세로(cm) x 높이(cm) / 5000) 중에서 더 무거운 값을 기준으로 아래와 같이 배송비가 산정됩니다</p>
    <p> - 예시</p>  
    <p>실제무게 = 2kg, 부피 = 42cm x 30cm x 18cm</p>
    <p>부피무게 = 42 x 30 x 18 / 5000 = 4.536kg</p>
    <p>실제무게는 2kg 이지만 무피무게가 더 크기 때문에 4.536kg 기준으로 배송비가 산정됩니다</p>
    <p>--> 114 + (4.536 / 0.5) x 20 = 295.44(HKD)</p>
    <br>
    <p><strong>[기업고객 - 홍콩배송비 계산 방법 - #1. 홍콩 -> 인천공항]</strong></p>
    <p> - 37hkd/kg</p>
    <br>
    <p><strong>[기업고객 - 홍콩배송비 계산 방법 - #2. 홍콩 -> 소비자]</strong></p>
    <p> - 기본 0.5kg 33hkd + 0.5kg 당 20hkd</p>
    <p> - ex) 3kg인 경우 -> 33hkd  + 5 x 20hkd = 133hkd</p>
    <br>
    <p><strong>[주의사항]</strong></p>
    <p> - 무게는 실제무게와 부피무게 중 무거운 걸 기준으로 합니다(부피무게 = 가로 x 세로 x 높이 / 5000)</p>
    <p> - (네이버에서 조회되는 홍콩 환율 + 10) 기준으로 최종 원화 비용 계산됩니다.</p>
    <p> - 부가세 별도입니다.</p>
    <p> - 물량이 적은 경우 요금 체계가 변경될 수 있습니다.</p>
  </div>  
  <div v-if="idx === '10'"> <!-- 배송기간 -->
    <p><strong>[대행 가능 국가]</strong><br>홍콩,프랑스,영국,독일,스페인,이탈리아,일본,중국,미국,싱가포르,호주,네덜란드,오스트리아,뉴질랜드,스웨덴,</p>
    <p>구매/배송대행이 가능한 국가는 위와 같고, 해당 국가에서 주문하신 상품을 한국에서 받기까지 걸리는 시간은 <span>"GEEK9에서 한국으로 출발한 시점"에서 "영업일" 기준으로 홍콩은 3~5일, 그 외 국가는 "최소" 2주</span>의 시간이 걸립니다. 이 기간은 대행 신청을 하신 물건이 한국 세관을 통과해서 구매자 님께서 해당 상품을 받을 때까지 걸리는 시간을 의미합니다.</p>
    <p>그리고 많은 분들께서 오해하시는 내용에 대해서 추가 설명을 드리겠습니다. 아래 내용을 꼭 필독 부탁드리겠습니다.</p>
    <p><span><strong>"GEEK9에서 한국으로 출발한 시점"</strong></span><br>해외 상품을 구매하게 되면 판매자가 GEEK9 배송 대행지 주소로 물건을 보내줘야 GEEK9에서 한국의 구매자 님께 해당 상품을 보내드릴 수가 있습니다. GEEK9에서 판매자로부터 물건을 받을 때까지 걸리는 기간은 나라마다 다르기도 하고, 판매자가 판매 상품을 얼마나 빨리 보내주느냐에 따라 결정되기 때문에 주문한 물건을 GEEK9에서 받을 때까지 얼마나 걸릴지는 알 수가 없습니다. 그래서 GEEK9에서 물건을 받아서 한국으로 보낸 시점 기준으로 배송기간(영업일 기준으로 홍콩은 3~5일, 그 외 국가는 최소 2주)을 안내해 드리고 있습니다.</p>
    <p><span><strong>"영업일"</strong></span><br>주말이나 한국 연휴, 해외 연휴, 그 외 기타 업무가 불가능한 기간 등은 배송 기간에 포함되지 않습니다.</p>
    <p><span><strong>"최소"</strong></span><br>영업일 기준으로 홍콩은 3~5일, 그 외 국가는 "최소" 2주의 시간이 필요하다고 말씀드렸습니다. 그 말은 해외 배송 대행지에서 한국으로 배송이 시작한 시점에서 아무리 빨라도 2주 뒤에 한국에서 물건을 받으신다는 의미이고, 2주가 넘게 걸리는 경우도 많다는 의미입니다.</p>
    <p>그 외에도 한국 세관에서 잘못된 수취인 정보나 세관 자체의 작업 지연으로 인해 배송 기간이 좀 더 걸릴 수도 있습니다.</p>
  </div>  
   <div v-if="idx === '11'"> <!-- 배송업체 -->
    <p>1. 한국에 도착한 물건은 우체국택배나 CJ대한통운을 통해 소비자 분들에게 배송이 됩니다.</p>
    <p>2. 홍콩과 싱가포르 한해서만 Fedex나 DHL을 이용해서 한국으로 배송이 가능합니다(따로 문의해주세요)</p>
  </div>
  <div v-if="idx === '12'"> <!-- 진행상황 -->
    <p>1. <span><strong><a href="https://geek9.kr/list" target="_blank">GEEK9 > 메뉴 > 주문조회</a></strong></span> 에서 조회 가능합니다.</p>
    <p>2. 해외 상품을 구매한 후 GEEK9에서 해당 물건을 받는 시점은 해외 판매자가 언제 배송을 해주느냐와 해외 현지 배송 업체가 얼마나 빨리 배송을 해주느냐에 따라 결정되기 때문에 GEEK9에서는 정확한 배송 시점을 알 수 없습니다.</p>
    <p>3. 해외 상품 구매에서부터 한국으로 배송이 도착할 때까지의 진행 상황은 대행 신청을 할 때 알려주신 이메일로 안내해 드리고 있습니다. <br>해당 메일을 못 받으신 경우에는 스팸으로 분류가 되어 있거나 잘못된 메일 주소를 알려주신 경우, 또는 진행된 상황이 없기 때문입니다.</p>
    <p>4. 진행상황</p>
    <p>주문접수 : 구매자 님의 주문이 확인되었고, 해외 판매자에게 구매요청해놓은 상태입니다.</p>
    <p>입금대기 : 구매자 님께서 GEEK9로 결제를 해주시면 해외 판매자에게 물건을 구매할 수 있는 상태입니다.</p>
    <p>입금완료 : 구매자 님의 결제 내역이 확인된 상태입니다. 곧 해외 판매자에게서 상품을 구매합니다.</p>
    <p>구매완료 : 해외 판매자에게서 상품 구매가 완료된 상태입니다. 해외 판매자가 상품을 보낼 때까지 기다려 주시기 바랍니다.</p>
    <p>입고대기 : 해외 판매자가 상품을 배송 보냈지만 아직 해당 상품이 GEEK9 창고에 도착하기 전 상태입니다.</p>
    <p>창고도착 : 판매자가 보낸 상품이 GEEK9 창고에 도착한 상태입니다. 한국으로 보내는 배송비 안내를 곧 받으실 수 있습니다.</p>
    <p>배송중 : 상품이 한국으로 배송 중입니다. 한국 세관을 거친 후에 구매자 님께 배송됩니다.</p>
    <p>거래완료 : 구매자 님께서 상품을 정상적으로 수령한 상태입니다.</p>
    <p>거래취소 : 해외 판매자 혹은 구매자 님의 요청으로 거래가 취소된 상태입니다. 해외 결제라서 환불까지 최소 한 달의 시간이 필요합니다.</p>
    <p>환불완료 : 구매자 님의 계좌로 환불이 완료된 상태입니다.</p>
  </div>   
  <div v-if="idx === '13'"> <!-- 사기거래 -->
    <p>1. 해외 판매자의 연락 두절, 구매한 사이트가 사기 사이트인 경우에는 GEEK9에서 책임을 지지 않습니다.</p>
    <p>2. GEEK9는 물품을 판매하는 것이 아니고, 구매자님께서 대행을 요청한 사이트나 판매자에게 구매자님 대신 문의/응대/구매를 한 후 한국으로 배송을 보내는 물류 업체에 구매한 물건을 맡기는 작업만을 하기 때문에 애초에 구매자님께서 요청하신 판매자/판매사이트가 사기인 경우에는 어떠한 보상도 해드릴 수가 없습니다. 구매처와 구매 여부는 전적으로 구매자님의 결정에 의한 것이고, GEEK9에서는 그 결정을 대신 진행만 하는 거라서 사기 거래 여부를 판단할 수가 없습니다.</p>
    <p>3. 하지만 사기거래가 발생한 경우 구매자님께서 요청하신 작업에 대해서는 가능한 일이라면 최대한 도움을 드리고 있습니다. 그러나 현실적으로 해외 판매자가 마음먹고 사기를 친다면 해외직구를 통해 구매하는 쪽에서 할 수 있는 일은 많지 않습니다. 신중하게 해외직구 대행 여부를 결정하시기 바랍니다.</p>
    <p><span> * 신중한 대행 결정을 부탁드리겠습니다.</span></p>
  </div>  
  <div v-if="idx === '14'"> <!-- 교환/환불/취소 -->
    <p>1. GEEK9 측 실수나 착오가 아닌 판매자/소비자 측의 과실, 단순 변심으로 인한 교환/환불/취소에 대해서는 GEEK9에서는 어떠한 보상이나 책임도 지지 않습니다. 하지만 교환/환불/취소 과정 중 GEEK9에서 지원이 가능한 일에 대해서는 도움을 드리고 있습니다. 단, 그 과정에서 발생하는 비용은 소비자 측에서 부담하셔야 됩니다.</p>
    <p>2. 교환/환불/취소가 정상적으로 완료가 되면 대행 수수료와 해외 이체 수수료, 카드 수수료 등은 차감해서 환불해 드립니다. 물건이 한국에 도착한 이후에 교환/환불/취소를 원하시는 경우에는 판매자로부터 교환/환불/취소가 가능한다는 답변이 필요하며, 가능하다는 답변을 받으면 한국에서 받으신 물건을 GEEK9 해외 주소지로 직접 보내주셔야 되며, 판매자한테 물건을 보내는 반송비가 발생합니다.</p>
    <p>3. GEEK9에 구매대행을 맡기신 경우에는 교환/환불/취소 요청을 판매자 측에 대신해드리지만 소비자 측에서 직접 결제한 후 GEEK9에 배송대행만 맡기신 경우에는 소비자가 직접 판매자 측에 교환/환불/취소 요청을 하신 후 관련 정보를 GEEK9에 알려 주셔야 됩니다.</p>
    <p>4. 상품의 하자, 배송 중 파손/분실 등은 GEEK9의 문제가 아닌 판매자나 배송사 측 과실이므로 이와 관련된 문제는 GEEK9에서 책임지지 않습니다.</p>
    <p>5. 환불신청은 문의게시판에 환불 받으실 계좌정보를 남겨주시면 됩니다.</p>
    <p>6. 전화/카톡/문자/이메일로 답변이 없다고 고소/고발/신고를 하시겠다는 개인 소비자 분들이 많습니다. GEEK9에서는 기업 고객이 아닌 개인 소비자 분들에 대해서는 전화/카톡/문자/이메일로 응대를 해드리지 않습니다. 개인 소비자 분들께서는 홈페이지의 문의 게시판을 통해서 문의를 부탁를 해주시기 바랍니다.</p>
  </div>  
  <div v-if="idx === '15'"> <!-- 세관통관 -->
    <p>1. 해외직구를 하면 무조건 세관 통관 과정을 거쳐야 합니다. 그리고 처음 해외직구를 했을 때 결제/배송과 함께 문제 일어날 여지가 많은 과정이 바로 세관 통관 과정입니다. 품목, 수량, 무게, 가격에 따라 세관에서 세금이나 과태료와 같은 추가적인 비용이 발생할 수도 있고, 세관 쪽에 소명을 하거나 최악의 경우 세관 통과가 되지 않아서 물건을 받지 못할 수도 있습니다.</p>
    <p>2. 해외 구매 가격이 150달러(미국은 200달러) 이상인 경우에는 한국 세관에서 관부가세가 발생하는 것처럼 확인이 쉬운 건 기본이고, 그 외에 해외에서 구매하려는 상품의 품목, 수량 등으로 인해 세관에서 문제가 발생할 수 있는지를 알아 본 후에 해외직구 여부를 결정해야 됩니다. 품목 별로 기준이 다 드리기 때문에 세관이나 사용하는 배대지에 문의를 해보는 게 가장 좋지만 100% 정확하지 않으며, 구매 여부, 수량, 옵션 등의 상품 정보는 최종적으로 소비자 쪽에서 결정하는 것이기 때문에 세관에서 발생하는 모든 문제는 소비자 측의 책임입니다.</p>
  </div>
  <div v-if="idx === '16'"> <!-- 아이폰 -->
    <p><strong>[신청]</strong></p><p><a href="/order">여기</a>에서 신청을 하시면 됩니다. 구매는 직접 하시고 배송만 geek9에 맡기시려면 배송대행을, 구매와 배송까지 맡기시려면 구매대행으로 신청하시면 됩니다. 배송대행으로 맡기시는 경우에는 아이폰 구매 후에 바로 확인할 수 있는 주문번호를 신청서에 반드시 입력해 주셔야 되고, 추후에 배송이 진행되면 애플 공홈에서 확인할 수 있는 트래킹 넘버도 업데이트 해주셔야 GEEK9에 물건이 도착했을 때 확인이 가능합니다. 해당 정보를 제때 입력해 주시지 않으시면 배송이 지연되거나 최악의 경우에는 물건을 찾지 못할 수도 있습니다. </p>
    <br><p><strong>[홍콩주소]</strong></p>
    <p>받는사람 : Sungho Bae (한국에서 받는 분의 성함과는 상관없이 무조건 Sungho Bae로 보내주세요)</p>
    <p>주소1 : Unit C1, 7/F Bonjour Tower</p>
    <p>주소2 : No. 36-42 Wang Wo Tsai Street, Tsuen Wan, N.T, HK</p>
    <p>전화번호 : 852-5316-1715</p>
    <br><p><strong>[홍콩주소기재방법]</strong></p>
    <img src="https://k.kakaocdn.net/dn/jpgv6/btsCF9rr6fx/QVKkpv1a6ykWt51ORIoMiK/img_l.jpg" alt="geek9.kr">
    <br><p><strong>[비용]</strong></p>
    <p><span style="color: red;">1. 구매대행</span></p><p>&nbsp; - 아이폰원가 + 5만원(구매대행수수료) <span style="font-style: italic; color: black;">(ex. 아이폰의 가격이 9,299hkd이고, 홍콩 환율이 170hkd이면 수수료 포함한 총 구매대행 비용은 9,299 x 170 + 5만원 = 1,630,830원)</span></p>
    <p>&nbsp; - , 한국으로 보내는 배송비(배송대행비)는 별도입니다.</p>
    <p><span style="color: red;">2. 배송대행(한국으로 보내는 배송비)</span></p><p>&nbsp; - 일반항공편배송(국내에서는 우체국 배송) : 38,000원,</p><p>&nbsp; - 페덱스 : 135,000원(페덱스 가격 상승)</p>
    <p><span style="color: red;">3. 관부가세</span> : 한국 세관에서 구매자 님께 연락이 가고, 직접 지불하시면 됩니다. 관부가세 관련 내용은 필독 게시판에 설명되어 있습니다.</p>
    <br><p>포장보완/사진촬영/관부가세처리를 원하시는 경우에는 신청서 작성 시 선택하시면 각각 추가 비용이 발생합니다.</p>
    <p>위의 내용 이외에 추가적으로 발생하는 비용 없습니다.</p>
    <br><p><strong>[배송기간]</strong></p>
    <p>애플이 GEEK9 홍콩 주소지로 보낸 물건이 도착한 시점에서 평일 기준으로 최소 7일 이후에 한국에서 받으실 수 있습니다. 한국/홍콩의 연휴, 세관에서의 지연 등으로 인해 배송 기간이 더 필요할 수도 있습니다.</p>
    <br><p><strong>[분실/파손/취소]</strong></p>
    <p>GEEK9에서는 구매자님께서 주신 상품/상품링크 정보를 기반으로 대신 구매하고, 한국으로 배송을 보내는 작업을 대행만 하는 업체이기 때문에 상품의 진품/파손/하자여부에 대해서는 보장하지 않습니다. 교환이나 취소를 원하시는 경우에 GEEK9에서 그에 필요한 작업을 도와드리기는 하지만 그에 따른 비용은 모두 구매자님께서 부담하셔야 되며, GEEK9에서는 그에 대한 결과에 대해 책임이나 보상을 하지 않습니다. 이점 유념하시고 신중한 대행 여부 결정을 부탁드립니다.</p>
  </div>  
  <div v-if="idx === '17'" style="width:100%;"> <!-- 상품링크 -->
    <img src="../../assets/ex.png" style="width:90%; max-width:800px;" alt="geek9.kr">
    <p>1. 상품링크는 해외직구를 하기 위한 필수정보이며, 실제 상품을 판매하는 웹 페이지의 주소입니다.</p>
    <p>2. 위의 사진과 같이 상품명, 해외가격, 구매버튼이 있는 페이지의 주소가 상품링크이고, 실제 구매하신 상품과 상품링크의 정보가 다를 경우 한국세관에서 문제가 될 수 있습니다.</p>
    <p>3. 만약 상품링크가 없으시면 실제 구매한 상품의 정보가 있는 다른 링크라도 입력해주셔야 됩니다. 하지만 만약 이런 경우에 세관에서 문제가 발생시 geek9에서 책임지지 않습니다.</p>
  </div>
  <div v-if="idx === '18'"> <!-- 상품명 -->
    <p>한글을 사용하시면 안되고, 상품링크에 있는 실제 상품명을 현지어로 입력해주시기 바랍니다. </p>
  </div>
  <div v-if="idx === '19'"> <!-- 사진링크 -->
    <img src="../../assets/ex1.png" style="width:80%; max-width:800px;" alt="geek9.kr">
    <p>위의 사진과 같이 구매하시려는 상품의 페이지에 있는 상품사진에서 마우스 우클릭을 하시면 사진링크를 복사하실 수 있습니다.</p>
  </div>
  <div v-if="idx === '20'"> <!-- 해외가격 -->
    <img src="../../assets/ex2.png" style="width:80%; max-width:800px;" alt="geek9.kr">
    <p>1. 상품링크에 있는 해외가격이나 실제 결제하신 해외 결제 금액입니다. 만약 세관에서 문제가 되었을 때 입력하신 해외가격이 정확하지 않으면 소명 과정이 복잡해집니다.</p>
    <p>2. 만약 동일한 상품을 여러 개 구매하신 경우에는 해외가격 항목에 한 개의 가격만 입력하시고, 구매수량에 구매하신 상품의 개수를 입력하시면 됩니다.</p>
    <p>3. 동일하지 않은 상품을 여러 개 구매하신 경우에는 상품 별로 따로 상품정보를 등록해주셔야 되고, 위의 사진에서처럼 "상품추가" 버튼을 눌러서 상품을 추가해주시면 됩니다. "상품추가"로 함께 주문한 상품은 합배송됩니다.</p>
    <p>4. 화폐단위는 입력하시지 않으셔도 되고, 숫자만 입력해주세요.</p>
 </div> 
  <div v-if="idx === '21'"> <!-- 구매수량  -->
    <p>실제 구매한 수량을 입력해주시면 되고, (해외가격 x 구매수량) 기준으로 총 구매비용이 150달러가 넘으면 한국 세관에서 관부가세가 발생합니다(미국은 200달러)</p>
  </div>
  <div v-if="idx === '22'"> <!-- 주문번호 -->
    <p>1. 구매대행을 맡기시는 경우에는 '1'을 입력해주세요.</p>
    <p> 
      2. 배송대행을 맡기시는 경우에는 반드시 정확하게 입력해주셔야 됩니다. 해외사이트에서 결제가 완료되면 구매/결제내역에서 주문번호를 확인하실 수 있으며, 사이트에 따라 바로 나오지 않는 경우도 있습니다. 이때는 주문번호를 '1'로 입력하신 후에 해외사이트에서 주문번호가 조회되면 그때 업데이트해주셔야 됩니다.
      주문번호와 트래킹넘버를 통해 주문내역이 확인되기 때문에 주문번호나 트래킹넘버를 정확하게 입력해주시지 않거나 늦게 입력을 해주시면 배송이 늦어지거나 물건이 분실될 수 있습니다. 이때는 geek9에서 책임지지 않습니다.
    </p>
  </div>
  <div v-if="idx === '23'"> <!-- 트래킹넘버 -->
    <p>1. 구매대행을 맡기시는 경우에는 입력해주시지 않으셔도 됩니다.</p>
    <p>2. 배송대행을 맡기시는 경우에는 해외사이트에서 트래킹넘버가 나오면 geek9에 주문해주신 내역에 해당 트래킹넘버를 입력해주셔야 geek9에서 물건 도착여부가 확인됩니다.트래킹넘버는 입력하지 않고, 주문번호만 입력하시는 경우에도 도착확인이 안되는 경우가 있으니 트래킹넘버는 반드시 입력해주시기 바랍니다.</p>
  </div>
  <div v-if="idx === '24'"> <!-- 추가포장 -->
    <p>파손 위험이 있는 상품은 추가포장 요청을 해주시기 바랍니다.</p>
    <p>하지만 추가포장을 하여도 배송 중에 파손은 발생할 수 있으며, 그런 경우 geek9에서 배상해 드리지 않습니다.</p>
  </div>
  <div v-if="idx === '25'"> <!-- 상품사진 -->
    <p>어떠한 경우에도 배송도착한 물건에 대한 사진 촬영은 비용이 발생하며, 내용물 촬영에 경우에는 촬영 후에 다시 포장을 해야 되기 때문에 포장비용도 추가됩니다.</p>
  </div>
  <div v-if="idx === '26'"> <!-- 문의 -->
    <p>GEEK9에서는 기업 고객이 아닌 개인 고객에 대해서는 전화/카톡/문자/이메일로 문의를 받지 않습니다.</p>
    <p>GEEK9에서 이미 대행신청을 하신 경우에는 신청하신 주문에 대한 정보가 필요하고,</p>
    <p>대행신청을 하시기 전이시라면 견적이나 대행 가능여부에 대한 답변을 드리기 위해서는 상품에 대한 정보와 구매/결제가 가능한 판매처 정보 등이 필요하기 때문에 해당 정보도 없이 무조건 전화/카톡/문자/이메일로 문의를 하셔도 GEEK9에서는 답변 드리기가 어렵기 때문입니다.</p>
    <p>전화/카톡/문자/이메일로 답변이 없다고 고소/고발/신고를 하시겠다는 개인 소비자 분들이 많습니다. 위의 내용을 유념하셔서 개인 소비자 분들께서는 홈페이지의 문의 게시판을 통해서 문의를 부탁드립니다.</p>
  </div>

</div>  
</template>
<script>
export default {
  props: {
    idx: String
  }
}
</script>
<style scoped>
span{
  color:red;
}
p {
  text-align: left;
}
</style>